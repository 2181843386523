import React, { memo } from 'react';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';
import StatusSelect from '~/components/StatusSelect';
import StoreSelect from '~/components/StoreSelect';
import TenantsSelect from '~/components/TenantsSelect';

import util from '~/assets/util';

interface FiltersProps {
  filters: any;
  setFilters: any;
  onSave: any;
  onClear: any;
}

function Filters({ filters, setFilters, onSave, onClear }: FiltersProps) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const id = ev.target.id;

    setFilters({ ...filters, [id]: ev.target.value });
  };

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-sm-6 col-md-4 col-xl-2">
          <Label htmlFor="date_start">{util.t('FROM')}</Label>
          <Input id="date_start" type="date" onChange={handleChange} value={filters.date_start} />
        </FormGroup>

        <FormGroup className="col-sm-6 col-md-4 col-xl-2">
          <Label htmlFor="date_end">{util.t('TO')}</Label>
          <Input id="date_end" type="date" onChange={handleChange} value={filters.date_end} />
        </FormGroup>

        <FormGroup className="col-md-4 col-lg-4 col-xl-2">
          <Label htmlFor="store_order_id">{util.t('ORDER_NUMBER')}</Label>
          <Input id="store_order_id" type="number" onChange={handleChange} value={filters.store_order_id} />
        </FormGroup>

        <FormGroup className="col-md-4 col-lg-4 col-xl-2">
          <Label htmlFor="order_id">{util.t('MAIN_ORDER_NUMBER')}</Label>
          <Input id="order_id" type="number" onChange={handleChange} value={filters.order_id} />
        </FormGroup>

        <FormGroup className="col-md-4 col-lg-4 col-xl-4">
          <Label htmlFor="store_id"> {util.t('DISTRIBUTION_CENTER')} </Label>
          <StoreSelect
            showOptionAll
            withPublicStores
            className="mb-3"
            id="store_id"
            value={filters.store_id}
            name="store_id"
            onChange={(id) => {
              setFilters({ ...filters, store_id: id });
            }}
          />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <Label htmlFor="status">{util.t('STATUS')}</Label>
          <StatusSelect id="status" value={filters.status} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-6 col-md-6 col-lg-2">
          <Label htmlFor="coupon">{util.t('COUPON')}</Label>
          <Input id="coupon" type="text" onChange={handleChange} value={filters.coupon} />
        </FormGroup>
        <FormGroup className="col-md-6 col-xl-4">
          <Label htmlFor="customer_name">{util.t('CUSTOMER_NAME')}</Label>
          <Input id="customer_name" type="text" onChange={handleChange} value={filters.customer_name} />
        </FormGroup>
        <FormGroup className="col-md-6 col-xl-3">
          <Label htmlFor="customer_email">{util.t('CUSTOMER_EMAIL')}</Label>
          <Input id="customer_email" type="text" onChange={handleChange} value={filters.customer_email} />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-6 col-xl-3">
          <Label htmlFor="store_id"> {util.t('STORES')} </Label>
          <TenantsSelect
            className="mb-3"
            id="tenant_id"
            name="tenant_id"
            clear={!filters.tenant_id.length}
            onChangeIds={(ids) => setFilters({ ...filters, tenant_id: ids })}
          />
        </FormGroup>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={() => onClear()} type="button" color="secondary" onClear={onClear}>
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
