import React, { useEffect, useState } from 'react';
import { format, subMonths } from 'date-fns';
import { Card, CardBody, CardHeader, Modal, Nav, NavItem, NavLink } from 'reactstrap';

import { FaMoneyBill, FaShoppingBag } from 'react-icons/fa';

import Filters from './Filters';
import InfoCard from '~/components/InfoCard';
import OrderDetails from '~/components/OrderDetails';
import OrdersTable from '~/components/OrdersTable';
import Pagination from '~/components/Pagination';
import SalesReportsBox from '~/components/SalesReportsBox';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { OrderOrigin } from '~/assets/constants';

import styles from './index.module.scss';

const today = new Date();
const DEFAULT_FILTERS = {
  date_start: format(subMonths(today, 1), 'y-MM-dd'),
  date_end: format(today, 'y-MM-dd'),
  store_order_id: '',
  status: '',
  coupon: '',
  customer_name: '',
  customer_email: '',
  order_id: '',
  store_id: '',
  origin: OrderOrigin.ECOMMERCE,
  tenant_id: [],
};

function Orders() {
  const [orderId, setOrderId] = useState<number | null>(null);

  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const [reports, setReports] = useState<any>({});

  const [orders, setOrders] = useState<any>(null);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true);

  const loadOrders = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    setLoadingOrders(true);

    try {
      const { data } = await api.get(`dashboard/orders?page=${page}`, {
        params: filters,
      });

      const { orders } = data;

      orders.links?.shift();
      orders.links?.splice(orders.links.length - 1, 1);

      setOrders(orders);
    } catch (e) {
      notification.$e(e);
      console.error(e);
    } finally {
      setLoadingOrders(false);
    }
  };

  const loadReports = async () => {
    try {
      const { data } = await api.get(`dashboard/orders/reports`, {
        params: filters,
      });
      setReports(data.reports);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    }
  };

  const doSearch = () => {
    loadOrders();
    loadReports();
  };

  const onChangeOrderStatus = () => {
    loadOrders();
  };

  useEffect(() => {
    doSearch();
  }, [filters.origin]);

  return (
    <>
      <header className="my-3">
        <section className="row">
          <div className="col-lg-6 col-xl-4 col-md-6 mb-3">
            <InfoCard title="ORDERS_RECEIVED" Icon={<FaShoppingBag size="50" />} value={reports.orders_received || '-'} />
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6 mb-3">
            <InfoCard title="TOTAL_SALES" Icon={<FaMoneyBill size="50" />}>
              <h2 className="m-0 mr-auto">{reports.total_sales ? `R$ ${util.format(reports.total_sales)}` : '-'}</h2>
            </InfoCard>
          </div>
        </section>

        <section className="row">
          <div className="col-12">
            <div className="card p-3">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={doSearch}
                onClear={() => {
                  setFilters(DEFAULT_FILTERS);
                }}
              />
            </div>
          </div>
        </section>
      </header>

      <main className="card">
        <div className="container-fluid p-3">
          <header className="mb-3 d-flex align-items-center justify-content-between">
            <h1 className="h2 m-0">{util.t('ORDERS')}</h1>
          </header>

          <section className="text-right">
            <SalesReportsBox disabled={orders?.length == 0 || loadingOrders} filters={filters} />
          </section>

          <Nav tabs className="mb-2">
            <NavItem className={styles.ordersTab}>
              <NavLink
                active={filters.origin === OrderOrigin.ECOMMERCE}
                onClick={() => setFilters({ ...filters, origin: OrderOrigin.ECOMMERCE })}>
                {util.t('ECOMMERCE')}
              </NavLink>
            </NavItem>
            <NavItem className={styles.ordersTab}>
              <NavLink
                active={filters.origin === OrderOrigin.CATAVENTO_PANEL}
                onClick={() => setFilters({ ...filters, origin: OrderOrigin.CATAVENTO_PANEL })}>
                {util.t('CATAVENTO_PANEL')}
              </NavLink>
            </NavItem>
          </Nav>

          <section>
            <OrdersTable
              loading={loadingOrders}
              orders={orders}
              onOpen={(id) => {
                setOrderId(id);
              }}
              setOrders={setOrders}
              onChangeOrderStatus={onChangeOrderStatus}
            />
          </section>

          <footer className="d-flex justify-content-end mt-4">
            {orders && <Pagination onChange={(page) => loadOrders(page)} {...orders} />}
          </footer>
        </div>
      </main>

      <Modal autoFocus={false} isOpen={orderId != null} size="xl" toggle={() => setOrderId(null)} unmountOnClose>
        <Card>
          <CardHeader>
            {util.t('ORDER')} <strong>{orderId}</strong>
          </CardHeader>
          <CardBody>
            <OrderDetails onChange={onChangeOrderStatus} id={orderId} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

export default Orders;
