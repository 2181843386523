import React, { memo, useEffect, useState } from 'react';

import { Spinner } from 'reactstrap';
import Select from 'react-select';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface TenantsSelectProps {
  clear: boolean;
  onChangeIds: any;
  [key: string]: any;
}

function TenantsSelect({ clear, onChangeIds, ...props }: TenantsSelectProps) {
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);

  const loadTenants = async () => {
    try {
      const res = await api.get('dashboard/tenants/names');

      const { tenants } = res?.data;

      setTenants(tenants);

      onChangeIds([]);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onChangeIds(selectedTenants.map((tenant) => tenant.tenant_id));
  }, [selectedTenants]);

  useEffect(() => {
    if (clear) {
      setSelectedTenants([]);
    }
  }, [clear]);

  useEffect(() => {
    loadTenants();
  }, []);

  if (loading) {
    return <Spinner color="primary" className="d-block mx-auto mb-3" size="sm" />;
  }

  return (
    <Select
      getOptionLabel={(opt) => opt.name}
      getOptionValue={(opt) => opt.tenant_id}
      noOptionsMessage={() => util.t('NO_OPTION')}
      options={tenants}
      placeholder={util.t('SELECT')}
      onChange={(value) => setSelectedTenants(value)}
      value={selectedTenants}
      isMulti
      {...props}
    />
  );
}

export default memo(TenantsSelect);
